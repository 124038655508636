<div class="discount discount__container">
  <app-loader #loader [cssClasses]="'loader--white'"></app-loader>
  <div class="modal-header">
    <div>
      <h2 class="m-0 fw-bold">{{ 'ORDER.DISCOUNT_MODAL.HEADER' | translate }}</h2>
    </div>
    <input class="d-none"/>
    <button type="button" class="btn btn-default p-0 m-1 d-flex" (click)="onClose()">
      <i class="icon ni-close"></i>
    </button>
  </div>
  <div class="discount__body" [formGroup]="form">
    <div class="discount__table">
      <!--<app-loader #secondLoader [cssClasses]="'loader&#45;&#45;white'"></app-loader>-->

      <div class="discount__line discount__line--header d-grid py-3 px-4 color-gray border-bottom">
        <div class="discount__items discount__items--checkbox">
          <app-check-mark
            class="checkbox"
            cssClasses="m-0"
            name="discount-check-all"
            [value]="areAllSelected()"
            (changed)="onSelectAll()"
          ></app-check-mark>
        </div>
        <div class="discount__items discount__items--item" [title]="'ORDER.DISCOUNT_MODAL.ITEM' | translate">
          {{ 'ORDER.DISCOUNT_MODAL.ITEM' | translate }}
        </div>
        <div class="discount__items discount__items--code" [title]="'ORDER.DISCOUNT_MODAL.CODE' | translate">
          {{ 'ORDER.DISCOUNT_MODAL.CODE' | translate }}
        </div>
        <div class="discount__items discount__items--texture" [title]="'ORDER.DISCOUNT_MODAL.TEXTURE' | translate">
          {{ 'ORDER.DISCOUNT_MODAL.TEXTURE' | translate }}
        </div>
        <div class="discount__items discount__items--quantity" [title]="'ORDER.DISCOUNT_MODAL.QUANTITY' | translate">
          {{ 'ORDER.DISCOUNT_MODAL.QUANTITY' | translate }}
        </div>
        <!-- PRICELIST PRICE for all roles, but different translations must be shown -->
        <div
          *appVisibleForUserRole="[userRole.ROLE_PM, userRole.ROLE_PM_RU]"
          class="discount__items discount__items--price"
          [title]="'ORDER.DISCOUNT_MODAL.PRICE' | translate"
        >
          {{ 'ORDER.DISCOUNT_MODAL.PRICE' | translate }}
        </div>
        <div
          *appVisibleForUserRole="[userRole.ROLE_PM_NARBUTAS]"
          class="discount__items discount__items--price"
          [title]="'ORDER.DISCOUNT_MODAL.STANDARD_PRICE' | translate"
        >
          {{ 'ORDER.DISCOUNT_MODAL.STANDARD_PRICE' | translate }}
        </div>
        <!-- QUANTITY_DISCOUNT instead of CUSTOM_PRICE for ROLE_PM and ROLE_PM_RU -->
        <div
          class="discount__items discount__items--quantity-discount"
          *appVisibleForUserRole="[userRole.ROLE_PM, userRole.ROLE_PM_RU]"
          [title]="'ORDER.DISCOUNT_MODAL.QUANTITY_DISCOUNT' | translate"
        >
          {{ 'ORDER.DISCOUNT_MODAL.QUANTITY_DISCOUNT' | translate }}
        </div>
        <!-- CUSTOM_PRICE instead of DISCOUNT for ROLE_PM_NARBUTAS -->
        <div
          class="discount__items discount__items--price discount__items--custom-price"
          *appVisibleForUserRole="[userRole.ROLE_PM_NARBUTAS]"
          [title]="'ORDER.DISCOUNT_MODAL.CUSTOM_PRICE' | translate"
        >
          {{ 'ORDER.DISCOUNT_MODAL.CUSTOM_PRICE' | translate }}
        </div>
        <!-- CUSTOM_DISCOUNT instead of DISCOUNT translation for ROLE_PM and ROLE_PM_RU -->
        <div
          class="discount__items discount__items--discount text-start"
          *appVisibleForUserRole="[userRole.ROLE_PM, userRole.ROLE_PM_RU]"
          [title]="'ORDER.DISCOUNT_MODAL.CUSTOM_DISCOUNT' | translate"
        >
          {{ 'ORDER.DISCOUNT_MODAL.CUSTOM_DISCOUNT' | translate }}
        </div>
        <!-- DISCOUNT instead of CUSTOM_DISCOUNT translation for ROLE_PM_NARBUTAS -->
        <div
          class="discount__items discount__items--discount text-start"
          *appVisibleForUserRole="[userRole.ROLE_PM_NARBUTAS]"
          [title]="'ORDER.DISCOUNT_MODAL.DISCOUNT' | translate"
        >
          {{ 'ORDER.DISCOUNT_MODAL.DISCOUNT' | translate }}
        </div>
        <div
          class="discount__items discount__items--discounted-unit-price"
          [title]="'ORDER.DISCOUNT_MODAL.DISCOUNTED_UNIT_PRICE' | translate"
        >
          {{ 'ORDER.DISCOUNT_MODAL.DISCOUNTED_UNIT_PRICE' | translate }}
        </div>
        <div class="discount__items discount__items--total" [title]="'ORDER.DISCOUNT_MODAL.TOTAL' | translate">
          {{ 'ORDER.DISCOUNT_MODAL.TOTAL' | translate }}
        </div>
      </div>
      <div *ngFor="let item of items; let i = index"
        [class.pt-3]="i === 0"
        class="discount__line discount__line--body d-grid px-4 pb-3 color-black"
        formArrayName="items"
      >
        <ng-container [formGroupName]="i">
          <div class="discount__items discount__items--checkbox">
            <app-check-mark
              class="checkbox"
              cssClasses="mx-0 my-2"
              [name]="'discount-check-item-' + item.id"
              [value]="isSelected(item.id)"
              (changed)="onSelect(item)"
            ></app-check-mark>
          </div>
          <div class="discount__items discount__items--item pe-2" [title]="item.longText">
            {{ item.longText }}
          </div>
          <div class="discount__items discount__items--code">
            {{ item.code }}
          </div>
          <div class="discount__items discount__items--texture" [title]="materialCodes[item.id].join(', ')">
            {{ materialCodes[item.id].join(', ') }}
          </div>
          <div class="discount__items discount__items--quantity position-relative">
            <i
              class="discount__items--icon ms-2"
              *ngIf="item.quantity !== item.orderQuantity || item.orderQuantity !== item.originalQuantity"
              tooltipOpener
              tooltipContent="{{ 'ORDER.DISCOUNT_MODAL.TOOLTIP' | translate: { number: item.orderQuantity } }}"
              [tooltipPosition]="'top-left'"
              [tooltipArrowPosition]="'bottom-left'"
              [additionalClasses]="'tooltip--discount'"
            >
            </i>
            {{ item.quantity }}
          </div>
          <div class="discount__items discount__items--price" *appVisibleForUserRole="[userRole.ROLE_PM_NARBUTAS]">
            <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
              {{ item.pricelistPrice }}
            </visibility-by-variables>
          </div>
          <div class="discount__items discount__items--price" *appVisibleForUserRole="[userRole.ROLE_PM, userRole.ROLE_PM_RU]">
            <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
              {{ item.type === orderArticleTypes.CUSTOM_ITEM_TYPE ? item.customPrice || 0 : item.pricelistPrice || 0 }}
            </visibility-by-variables>
          </div>
          <div class="discount__items discount__items--quantity-discount" *appVisibleForUserRole="[userRole.ROLE_PM, userRole.ROLE_PM_RU]">
            <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
              {{ item.formattedUserDiscount }}{{ item.formattedUserDiscount > 0 ? '%' : '' }}
            </visibility-by-variables>
          </div>
          <div
            class="discount__items--price discount__items--custom-price discount__items--with-checkbox d-flex justify-content-end justify-content-between"
            *appVisibleForUserRole="[userRole.ROLE_PM_NARBUTAS]"
          >
            <app-check-mark
              class="checkbox"
              cssClasses="my-0 me-2"
              [name]="'allow-enter-custom-price-' + item.id"
              [value]="item.customPriceAllowed"
              (changed)="onPropertyAllowed(propertyTypes.CUSTOM_PRICE_ALLOWED, i)"
              labelClasses="before-color-black"
            ></app-check-mark>
            <input id="custom-price-input"
              class="form-control px-2"
              [placeholder]="'FILTERABLE_LIST.COLUMNS.PRICE' | translate"
              formControlName="customPrice"
              (input)="onCustomPriceChanged($event, i)"
              [class.is-invalid]="itemsFormArray.at(i).get('customPrice').invalid && !itemsFormArray.at(i).get('customPrice').valid"
            >
          </div>
          <div
            class="discount__items--discount discount__items--with-checkbox d-flex justify-content-end justify-content-between align-items-center"
            [ngClass]="{ 'pm-discount': isPm }"
          >
            <app-check-mark
              *appVisibleForUserRole="[userRole.ROLE_PM, userRole.ROLE_PM_RU, userRole.ROLE_PM_NARBUTAS]"
              class="checkbox d-flex align-items-center"
              cssClasses="my-0 me-2"
              [name]="'allow-enter-custom-discount-' + item.id"
              [value]="item.customDiscountAllowed"
              (changed)="onPropertyAllowed(propertyTypes.CUSTOM_DISCOUNT_ALLOWED, i)"
            ></app-check-mark>
            <div *ngIf="item.customDiscountAllowed" class="position-relative">
              <input id="discount-input"
                class="form-control px-2"
                formControlName="discount"
                (input)="onCustomDiscountChanged($event, i)"
                [class.is-invalid]="itemsFormArray.at(i).get('discount').invalid && itemsFormArray.at(i).get('discount').errors"
                [appFocusOnInit]="isPm"
              >
              <div class="input-percent-suffix">
                <div class="invisible">{{ itemsFormArray.at(i).get('discount').value }}</div>
                <div class="color-black">%</div>
              </div>
            </div>
          </div>
          <div class="discount__items discount__items--discounted-unit-price">
            {{ item.formattedPrice | priceFormat }}
          </div>
          <div class="discount__items discount__items--total">
            <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
              {{ item.totalPrice | priceFormat }}
            </visibility-by-variables>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="d-flex justify-content-end p-4 color-gray-600 border-top">
      <div class="discount__totals--text d-flex flex-column text-end">
        <div>
          {{ 'ORDER.DISCOUNT_MODAL.TOTALS.PRICE' | translate }}
        </div>
        <div>
          {{ 'ORDER.DISCOUNT_MODAL.TOTALS.DISCOUNT' | translate }}
        </div>
        <div>
          {{ 'ORDER.DISCOUNT_MODAL.TOTALS.FULL_PRICE' | translate }}
        </div>
      </div>
      <div class="discount__totals--price d-flex flex-column">
        <div class="">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ totals.price | priceFormat | pricelistCurrencySymbol }}
          </visibility-by-variables>
        </div>
        <div class="">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ totals.discount | priceFormat | pricelistCurrencySymbol }}
          </visibility-by-variables>
        </div>
        <div class="">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ totals.discountedPrice | priceFormat | pricelistCurrencySymbol }}
          </visibility-by-variables>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-end px-4 pb-4 color-gray-600"
      *permissionsElementVisibilityDecisionMaker="[permissionActions.CAN_SEE_PRICE_WITH_VAT]"
    >
      <div class="discount__totals--text d-flex flex-column">
        <div class="text-end">
          {{ 'ORDER.DISCOUNT_MODAL.TOTALS.VAT' | translate: { vatPercentage: totals.vatPercentage } }}
        </div>
        <div>
          {{ 'ORDER.DISCOUNT_MODAL.TOTALS.TOTAL_PRICE_WITH_VAT' | translate }}
        </div>
      </div>
      <div class="discount__totals--price d-flex flex-column">
        <div class="">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ totals.vat | pricelistCurrencySymbol }}
          </visibility-by-variables>
        </div>
        <div class="">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ totals.priceWithVatApplied | priceFormat | pricelistCurrencySymbol }}
          </visibility-by-variables>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer align-items-stretch" [formGroup]="discountForm">
    <div class="d-flex align-items-stretch flex-grow-1">
      <div class="ms-4 d-flex align-items-center border-end pe-2" style="width: 8rem;">
        <label for="discount-selected-input" class="discount__footer--sign me-2">%</label>
        <input id="discount-selected-input" class="form-control px-2"
          [placeholder]="'ORDER.DISCOUNT_MODAL.DISCOUNT_PLACEHOLDER' | translate"
          formControlName="discount"
          [class.is-invalid]="discountForm.get('discount').invalid && discountForm.get('discount').touched"
        >
      </div>
      <div class="ps-2 d-flex align-items-center">
        <button class="btn btn-primary" [disabled]="!discountForm.valid" (click)="onApply()">
          {{ 'ORDER.DISCOUNT_MODAL.APPLY_TO_ALL_SELECTED' | translate }}
        </button>
      </div>
    </div>
    <div class="d-flex border-start">
      <div class="btn btn-secondary btn-lg rounded-0 mw-11r" (click)="onClose()">
        {{ 'ORDER.DISCOUNT_MODAL.CANCEL' | translate }}
      </div>
      <div class="btn btn-primary btn-lg rounded-0 mw-11r" [class.discount__buttons--disabled]="!form.valid" (click)="onSave()">
        {{ 'ORDER.DISCOUNT_MODAL.ADD' | translate }}
      </div>
    </div>
  </div>
</div>
