<div
  class="d-flex flex-column w-100 position-fixed overflow-auto"
  [style.maxHeight]="'calc(100dvh - ' + (resizeObserver.getCurrentValue().navbarBorderBox?.height ?? 0) + 'px)'"
  >
  <div class="d-flex bg-gray-400 px-4 py-3">
    <i class="icon ni-info color-gray-900"></i>
    <p class="info-text color-black m-0">{{ 'NAVBAR.MOBILE_INFO_TEXT' | translate }}</p>
  </div>

  <div class="bg-white px-4 py-4 border-bottom">
    <div class="d-flex justify-content-between w-100 pb-3 border-bottom">
      <div
        class="d-flex align-items-center rounded-pill ps-2 pe-3 py-1"
        [ngClass]="{ 'bg-warning color-white': saleMode, 'shown-bg-gray-900 shown-color-gray-400': !saleMode }"
      >
        <img [src]="user && user.profilePicture ? user.profilePicture.url : 'assets/images/user/user@2x.png'" class="user-image" />
        <span class="ms-3" [ngClass]="saleMode ? 'color-white' : 'color-black'">
          {{ user?.firstName || user?.email }}
        </span>
      </div>

      <div class="d-flex">
        <button class="bg-white border-0" (click)="onNotificationsModalOpen()" data-testid="notificationsButton">
          <i class="icon ni-notification-bell position-relative color-black">
            <app-notification [notifications]="user?.unseenNotifications"></app-notification>
          </i>
        </button>

        <button class="bg-white border-0 ps-3" (click)="onMessagesModalOpen()" data-testid="messagesButton">
          <i class="icon ni-chat-square-plain position-relative color-black">
            <app-notification [notifications]="user?.unreadMessages"></app-notification>
          </i>
        </button>
      </div>
    </div>

    <div
      class="mt-4 border-bottom"
      [ngClass]="[
        isRouteActive(inquiriesRoutePaths.ROOT) ? 'color-primary' : 'color-black',
        isCollapsed(expendableGroups.INQUIRIES) ? 'pb-4' : 'pb-075r'
      ]"
    >
      <div class="d-flex cursor-pointer" (click)="toggleCollapse(expendableGroups.INQUIRIES)" data-testid="inquiriesGroupToggler">
        <i
          class="icon ni-arrow-drop-right icon-transitions"
          [ngClass]="{
            'rotate-down' : !isCollapsed(expendableGroups.INQUIRIES),
            'invisible': isCollapsed(expendableGroups.INQUIRIES) && !isRouteActive(inquiriesRoutePaths.ROOT)
          }"
        ></i>
        <div>{{ 'NAVBAR.INQUIRIES.TITLE' | translate }}</div>
      </div>

      <div #collapse="ngbCollapse" [ngbCollapse]="isCollapsed(expendableGroups.INQUIRIES)">
        <div class="pt-075r">
          <button
            class="group-button d-flex ps-3r py-075r w-100 border-0"
            [ngClass]="isRouteActive(claimsRoutePath) ? 'color-primary' : 'color-black'"
            (click)="navigateTo(claimsRoutePath)"
            data-testid="claimsNavButton"
          >
            {{ 'NAVBAR.INQUIRIES.CLAIMS' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div
      class="mt-4 border-bottom"
      [ngClass]="[isRouteActive(termsRoutePath) ? 'color-primary' : 'color-black', isCollapsed(expendableGroups.PROFILE) ? 'pb-4' : 'pb-075r']"
    >
      <div
        class="d-flex cursor-pointer"
        (click)="toggleCollapse(expendableGroups.PROFILE)"
        [class.mb-4]="!isCollapsed(expendableGroups.PROFILE)"
        data-testid="profileGroupToggler"
      >
        <i
          class="icon ni-arrow-drop-right icon-transitions"
          [ngClass]="{
            'rotate-down' : !isCollapsed(expendableGroups.PROFILE),
            'invisible': isCollapsed(expendableGroups.PROFILE) && !isRouteActive(termsRoutePath)
            }"
        ></i>
        <div>{{ 'NAVBAR.PROFILE' | translate }}</div>
      </div>

      <div #collapse="ngbCollapse" [ngbCollapse]="isCollapsed(expendableGroups.PROFILE)">
        <div class="d-flex justify-content-between ps-3r pb-075r">
          <p class="m-0 color-black">{{ 'CATALOGUE.LIST_MODE_SWITCH.SALE' | translate }}</p>
          <app-list-mode-switch [displayLabel]="false"></app-list-mode-switch>
        </div>

        <button
          class="group-button d-flex align-items-center ps-3r py-075r w-100 border-0"
          [ngClass]="isRouteActive(termsRoutePath) ? 'color-primary' : 'color-black'"
          (click)="navigateTo(termsRoutePath)"
          data-testid="termsNavButton"
        >
          <i class="icon ni-privacy me-3"></i>
          <span>{{ 'TERMS.PRIVACY_AND_TERMS' | translate }}</span>
        </button>

        <button class="group-button d-flex align-items-center ps-3r py-075r w-100 border-0 color-black" (click)="logout()" data-testid="logoutButton">
          <i class="icon ni-logout-bracket me-3"></i>
          <span>{{ 'USER.LOGOUT' | translate }}</span>
        </button>
      </div>
    </div>

    <div class="d-flex mt-4">
      <app-language-switch
        class="ms-auto"
        toggleClass="btn text-uppercase color-black"
        dropdownOpenToggleClass="bg-gray-400"
        [upwards]="true"
      ></app-language-switch>
    </div>
  </div>
</div>

<div class="backdrop w-100 h-100" (click)="onBackDropClick()" data-testid="backdrop"></div>
