<ng-template #popoverContent>
  <div class="text-start"
       [style.min-width.rem]="15.5"
       (mouseleave)="muted ? popover.close() : null"
  >
    <!-- Totals -->
    <div class="fw-bold">
      {{ details.selectedItemTotalPrice | priceFormat:{zeroReplacement: '-', digitsInfo: '1.2-2'} | pricelistCurrencySymbol }}
    </div>
    <div>{{ details.selectedItemTitle }}</div>

    <div class="mt-2">
      <div class="fw-bold">
        + {{ details.otherItemsTotalPrice | priceFormat:{zeroReplacement: '-', digitsInfo: '1.2-2'} | pricelistCurrencySymbol }}
      </div>
      <div class="d-flex">
        <div class="flex-grow-1">
          {{ 'CONFIGURATOR_MODAL.OTHER_PARTS_CHOSEN' | translate: {count: details.otherItemsCount} }}
        </div>
        <a (click)="onDetailedView()">{{ 'CONFIGURATOR_MODAL.CONTROLS.VIEW' | translate }}</a>
      </div>
    </div>

    <!-- Discounts -->
    <div class="mt-2" *ngIf="details.discount > 0">
      <div class="fw-bold ">
        % {{ (details.discount * 100) / details.price | priceFormat: { digitsInfo: '1.2-2'} }}
      </div>
      <div class="d-flex">
        <div class="flex-grow-1">
          {{ 'CONFIGURATOR_MODAL.TOTAL_DISCOUNT_APPLIED' | translate }}
        </div>
        <a (click)="onDetailedView()">{{ 'CONFIGURATOR_MODAL.CONTROLS.VIEW' | translate }}</a>
      </div>
    </div>

    <!-- Warning -->
    <div class="mt-2 color-warning" *ngIf="showWarning">
      {{ 'CONFIGURATOR_MODAL.PRICE_DETAILS.WARNING_CONTENT' | translate }}
    </div>

    <!-- Controls -->
    <div class="mt-2 pt-2 border-top d-flex justify-content-between">
      <!-- Mute -->
      <a *ngIf="!muted; else showOnChanges"
        (click)="onMute()"
      >
        {{ 'CONFIGURATOR_MODAL.CONTROLS.DO_NOT_SHOW_AGAIN' | translate }}
      </a>
      <ng-template #showOnChanges>
        <a class="" (click)="onUnmute()">{{ 'CONFIGURATOR_MODAL.CONTROLS.SHOW_ON_CHANGES' | translate }}</a>
      </ng-template>

      <!-- Close -->
      <a (click)="popover.close()">{{ 'CONFIGURATOR_MODAL.CONTROLS.CLOSE' | translate }}</a>
    </div>
  </div>
</ng-template>

<div
  class="me-2"
  [class.invisible]="!details?.otherItemsCount"

  #popover="ngbPopover"
  [ngbPopover]="popoverContent"
  [autoClose]="false"
  triggers="manual"
  popoverClass="shadow"

  (mouseenter)="muted ? popover.open() : null"
  (click)="popover.open()"
  (focus)="popover.open()"
>
  <i class="icon ni-info d-block"></i>
</div>
