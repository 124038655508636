<ng-container *ngIf="authenticated">
  <div
    class="sticky-navbar"
    [class.d-block]="!isMobile"
    [class.mw-xs-xl-1280p]="!isMobile"
    [class.d-xl-block]="isMobile"
    [class.d-none]="isMobile"
    appFullScreenMode
    [appBorderBoxResizeObserver]="'navbarBorderBox'"
    >
    <app-navbar></app-navbar>
    <app-sub-navbar class="app-sub-navbar"></app-sub-navbar>
  </div>
  <app-third-navbar [class.d-none]="isMobile" class="d-xl-block" *ngIf="isThirdNavbarOpen"></app-third-navbar>

  <app-mobile-navbar *ngIf="isMobile" class="sticky-navbar d-block d-xl-none vw-100" [appBorderBoxResizeObserver]="'navbarBorderBox'"></app-mobile-navbar>
  <app-burger-menu class="d-xl-none" *ngIf="isBurgerMenuOpen"></app-burger-menu>
</ng-container>

<ng-container *ngIf="showSimpleNav$ | async">
  <app-simple-navbar></app-simple-navbar>
</ng-container>
