import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService } from '../api.service';
import { ContentModel } from './content.model';
import { ContentTreeModel } from './content-tree.model';
import {ContentTranslationModel} from "./content-translation.model";

interface ContentTreeSortParams {
  sortBy: 'id'|'updatedAt';
  sortDir?: 'ASC'|'DESC';
}

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private item$: ReplaySubject<ContentModel.Data> = new ReplaySubject(1);
  private list$: ReplaySubject<ContentModel.Data[]> = new ReplaySubject(1);
  private pagination$: ReplaySubject<ContentModel.Pagination> = new ReplaySubject(1);


  constructor(private api: ApiService) {}

  resetSubjects() {
    this.item$.complete();
    this.item$ = new ReplaySubject(1);

    this.list$.complete();
    this.list$ = new ReplaySubject(1);

    this.pagination$.complete();
    this.pagination$ = new ReplaySubject(1);
  }

  getItemAsObservable() {
    return this.item$.asObservable();
  }

  setItemValue(data: ContentModel.Data) {
    this.item$.next(data);
  }

  getListAsObservable() {
    return this.list$.asObservable();
  }

  setListValue(data: ContentModel.Data[]) {
    this.list$.next(data);
  }

  getPaginationAsObservable() {
    return this.pagination$.asObservable();
  }

  setPaginationValue(data: ContentModel.Pagination) {
    this.pagination$.next(data);
  }

  getContentList(uri: string, page: number): Observable<ContentModel.ListResponse> {
    return this.api.get(`content/list/${uri}`, { page }).noCache();
  }

  getContentItem(uri: string): Observable<ContentModel.ItemResponse> {
    return this.api.get(`content/item/${uri}`).noCache();
  }

  getContentTree(baseUri: string): Observable<ContentTreeModel.Response> {
    return this.api.get(`content/tree/${baseUri}`).noCache();
  }

  getContentTranslations(base: string[]): Observable<ContentTranslationModel.Response> {
    return this.api.post(`content/translations`, { base });
  }
}
