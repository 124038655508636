import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DiscountInterface } from '../../core/models/discount.model';
import { Subscription } from 'rxjs';
import { ListModeSwitchService } from '../../shared/components/list-mode-switch/list-mode-switch.service';
import { UserService } from '../../core/services/user/user.service';
import { SaleMode } from '../../shared/components/list-mode-switch/sale-mode.types';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html'
})
export class DiscountComponent implements OnInit, OnDestroy {
  discount: DiscountInterface;
  isOrderMode: boolean;

  subscriptions = new Subscription();

  constructor(
    private userService: UserService,
    private listModeSwitchService: ListModeSwitchService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.userService.userObservable().subscribe(({ discount }) => {
        this.discount = discount;
        this.changeDetectorRef.detectChanges();
      })
    );

    this.subscriptions.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe(mode => {
        this.isOrderMode = mode === SaleMode.ORDER;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
