<div class="p-4">
  <div class="mb-4 fs-4">{{ 'CATALOGUE.INFO_BLOCKS.SYSTEM_FINISHES' | translate }}:</div>
  <div *ngFor="let finishTypeGroup of finishesGroupedByType">
    <h3>{{ finishTypeGroup.finishType.title }}</h3>
    <div *ngFor="let finish of finishTypeGroup.finishItems">
      <div class="mb-2">
        <a
          [routerLink]="['/info/finishes', finish.id]"
          class="btn btn-link"
          target="_blank"
        >
          <span class="d-flex justify-content-center align-items-center">
            {{ finish.title }}
            <i class="icon ni-open-in-new"></i>
          </span>
        </a>
      </div>
      <ul class="px-0">
        <ng-container
          *ngFor="let texture of finish.articlePropertyValueTextures"
        >
          <ng-container *ngIf="texture.images || texture.valueFrom.length > 2">
            <li class="list-inline-item finish-value me-1">
              <ng-container *ngIf="getUncachedImageBackgroundLowestToHighest(texture.images) | async as image; else materialPlaceholderImage">
                <img
                  *ngIf="!MaterialsHelper.isMaterialPlaceholderImage(image); else materialPlaceholderImage"
                  [src]="image"
                  #origin
                  (mouseenter)="onOpenPopover(finish, texture, popover, origin)"
                  (mouseleave)="onClosePopover()"
                  class="finish-image cursor-pointer overflow-hidden"
                  [alt]="texture.valueFrom"
                />
              </ng-container>
            </li>
            <ng-template #materialPlaceholderImage>
              <div class="finish-image cursor-pointer mt-0 overflow-hidden d-flex align-items-center justify-content-center">
                <img
                  class="material-thumbnail-placeholder"
                  src="/assets/icons/icon-material-placeholder-small.svg"
                  #origin
                  (mouseenter)="onOpenPopover(finish, texture, popover, origin)"
                  (mouseleave)="onClosePopover()"
                />
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </ul>

    </div>
  </div>
</div>

<ng-template #popover let-img="img" let-value="value">
  <div class="material-preview-popover" [ngClass]="{ 'm-0': !img }">
    <svg
        width="110"
        height="110"
        class="material-image"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4h48.324L55.199 0l6.875 4.4H110V110H0V4.4z" />
        <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="110" height="110">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4h48.324L55.199 0l6.875 4.4H110V110H0V4.4z" fill="#fff" />
        </mask>
        <g mask="url(#a)">
          <path fill="url(#pattern0)" d="M-10-2.917h120v120h-120z" />
        </g>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0" transform="scale(.01)" />
          </pattern>
          <image
            id="image0"
            [attr.width]="MaterialsHelper.isMaterialPlaceholderImage(img) ? 70 : 110"
            [attr.height]="MaterialsHelper.isMaterialPlaceholderImage(img) ? 70 : 110"
            [attr.x]="MaterialsHelper.isMaterialPlaceholderImage(img) ? 20 : 0" 
            [attr.y]="MaterialsHelper.isMaterialPlaceholderImage(img) ? 15 : 0"
            [attr.xlink:href]="img || '../../../assets/icons/icon-material-placeholder-large.svg'"
          />
        </defs>
      </svg>
    <div class="popover-text bg-gray-800 color-white text-center">
      {{ value.description }}
      <span
        *ngIf="value.extraPublicCode || value.extraCode"
        class="ms-1"
      >
        ({{ value.extraPublicCode ?? value.extraCode }})
      </span>
    </div>
  </div>
</ng-template>
