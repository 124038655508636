<app-generic-modal
  [closable]="false"
  headerClasses="py-3 px-4"
  bodyClasses="p-0"
  [mobileResponsiveFooter]="true"
>
  <ng-template appModalHeader>
    <h2 class="mb-0">
      {{ textHeader }}
    </h2>
  </ng-template>

  <ng-template appModalBody>
    <p class="p-4 mb-0">{{ textInfo }}</p>
  </ng-template>

  <ng-template appModalFooter>
    <button
      class="btn btn-primary btn-lg rounded-0 mw-11r w-100 w-sm-auto"
      (click)="doReload()"
    >
      {{ textAction }}
    </button>
  </ng-template>
</app-generic-modal>
