<app-loader #loader></app-loader>
<ng-container *ngIf="!loader.loading">
  <table class="table table-article-code color-black table-borderless m-0 small">
    <thead class="color-gray-500">
      <tr>
        <th scope="col" class="col-4 text-start">
          {{!components.length ? '&nbsp;' : columns.components}}
        </th>
        <th scope="col">{{columns.code}}</th>
        <th scope="col">{{columns.weight}}</th>
        <th scope="col">{{columns.volume}}</th>
        <th scope="col">{{columns.qnt}}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="text-end" *ngFor="let component of components">
        <td class="text-start"><span>{{component.text}}</span></td>
        <td>
          <a class="color-black hover-color-primary"
            [routerLink]="['/catalogue/search']"
            [queryParams]="{term: component.code}"
          >{{component.code}}</a>
        </td>
        <td>{{component.weight * component.qnt | number : '.0-2'}}</td>
        <td>{{component.volume * component.qnt | number : '.0-2'}}</td>
        <td>{{component.qnt}}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="text-end">
        <td></td>
        <td>{{code}}</td>
        <td>{{(weight ?? 0) | number : '.0-2'}}</td>
        <td>{{(volume ?? 0) | number : '.0-2'}}</td>
        <td>{{!components.length ? 1 : totalQnt}}</td>
      </tr>
    </tfoot>
  </table>
</ng-container>
