<nav class="d-flex justify-content-between align-items-center py-075r px-3 bg-black w-100">
  <ng-container *ngIf="!burgerMenuOpen; else openedBurgerMenuNavbar">
    <a class="d-block n-logo mx-0" [routerLink]="claimsRoutePath" app-logo></a>

    <button class="d-flex align-items-center bg-black color-white border-0" (click)="openBurgerMenu()" data-testid="burgerMenuOpener">
      <i class="icon icon-large ni-menu"></i>
    </button>
  </ng-container>
</nav>

<ng-template #openedBurgerMenuNavbar>
  <a class="d-block mx-0" [routerLink]="claimsRoutePath"> 
    <div class="nspace-logo"></div>
  </a>

  <button class="d-flex align-items-center bg-black color-white border-0" (click)="closeBurgerMenu()" data-testid="burgerMenuCloser">
    <i class="icon icon-large ni-close"></i>
  </button>
</ng-template>
