import { Component } from '@angular/core';

@Component({
  selector: 'app-sale-coefficients',
  templateUrl: './sale-coefficients.component.html',
  styleUrls: ['./sale-coefficients.component.scss']
})
export class SaleCoefficientsComponent {

  constructor() { }

}
