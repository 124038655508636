<app-navbar-tree></app-navbar-tree>
<div [class.mw-xs-xl-1280p]="loggedIn && !isMobile" [class.mw-xl-1280p]="loggedIn && isMobile">
  <app-header-message></app-header-message>
  <tooltip></tooltip>
  <div appContainer>
    <router-outlet></router-outlet>
  </div>
  <app-maintenance-banner *ngIf="loggedIn"></app-maintenance-banner>
  <app-consent-modal />
</div>
<app-toast-container aria-live="polite" aria-atomic="true"></app-toast-container>
