<div ngbDropdown #customDropdown="ngbDropdown" class="nav-item custom-dropdown" [ngClass]="cssClasses" [placement]="placement">
  <a ngbDropdownToggle class="nav-link" [ngClass]="linkCssClasses" data-testid="dropdownToggler">
    {{ activatorTextField ? data.selected[activatorTextField] : textField ? data.selected[textField] : data.selected?.name || data.selected?.language }}
  </a>
  <div ngbDropdownMenu class="nav-dropdown__menu shadow border-0 p-0" [ngClass]="placement" aria-labelledby="customDropDown">
    <button
      class="dropdown-item btn btn-secondary text-decoration-none border-bottom"
      *ngFor="let value of data.values"
      (click)="data.onSelect(value); customDropdown.close()"
      [ngClass]="listItemClasses"
      data-testid="dropdownMenuItem"
    >
      {{ textField ? value[textField] : value.name || value.language }}
    </button>
  </div>
</div>
