import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PriceListCurrencyComponent} from './pricelist-currency/pricelist-currency.component';
import {PriceListTitleComponent} from './pricelist-title/pricelist-title.component';
import { DiscountComponent } from './discount/discount.component';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '../core/models/custom-missing-translation-handler.model';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
  ],
  declarations: [PriceListCurrencyComponent, PriceListTitleComponent, DiscountComponent],
  exports: [PriceListCurrencyComponent, PriceListTitleComponent, DiscountComponent]
})
export class PriceListDetailsModule {
}
