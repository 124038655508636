import { Component } from '@angular/core';
import { CatalogueType } from '../../../core/enums/catalogue-type.enum';
import { SaleCoefficientsBaseComponent } from '../sale-coefficients.base.component';
import { SaleCoefficientsDataInterface } from '../../../core/models/sale-coefficient.model';

@Component({
  selector: 'app-custom-made-furniture',
  templateUrl: '../coefficient-table.component.html',
  styleUrls: ['../sale-coefficients.component.scss'],
  host: { class: 'd-flex overflow-hidden' },
})
export class CustomMadeFurnitureComponent extends SaleCoefficientsBaseComponent {
  protected type = CatalogueType.CUSTOM_MADE_FURNITURE;
  public currentSaleCoefficient: SaleCoefficientsDataInterface;
}
