<ng-container *ngIf="{
  selected: selected$ | async,
  all: languages$ | async
} as data">
  <div ngbDropdown
       [ngClass]="cssClass"
       *ngIf="data.selected"
       [container]="upwards ? 'body' : null"
       [placement]="upwards ? 'top-end' : 'bottom-end'"
       (openChange)="onDropdownOpenChange($event)"
  >
    <button ngbDropdownToggle
            type="button"
            [ngClass]="[toggleClass, dropdownOpen ? dropdownOpenToggleClass : '']"
    >{{data.selected.short}}</button>

    <div ngbDropdownMenu
         [ngClass]="menuClass"
    >
      <button ngbDropdownItem
              *ngFor="let item of data.all"
              class="border-bottom"
              [ngClass]="itemClass"
              (click)="onSelect(item)"
      >{{item.language}}</button>
    </div>
  </div>
</ng-container>
