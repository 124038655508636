import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LanguageConstants } from './language.constants';
import { UserService } from '../user/user.service';

enum UnitSystem {
  METRIC = 0,
  IMPERIAL = 1
}

export interface LanguageInterface {
  language: string;
  id: number;
  short: string;
  unitsSystem: UnitSystem;
  translated?: string; // DocumentTemplateComponent.onInit will add this from translation file values
}

@Injectable()
export class LanguageStoreHandlerService {
  protected values$: ReplaySubject<LanguageInterface[]> = new ReplaySubject<LanguageInterface[]>(1);
  protected current$: ReplaySubject<LanguageInterface> = new ReplaySubject<LanguageInterface>(1);

  constructor(protected api: ApiService, private translateService: TranslateService, private userService: UserService) {}

  fetch(): Observable<any> {
    // Initial language set from sessionStorage
    const sessionLang = this.getFromStorage();
    if (sessionLang) {
      this.translateService.use(sessionLang.short);
    }
    return this.getInitLanguage().pipe(
      switchMap(language => {
        return this.api.get('language/').pipe(
          map(({ data }) => data),
          tap(languages => {
            this.values$.next(languages);
            if (language) {
              this.switch(language);
            } else {
              if (!language) {
                this.switch(languages && languages.length ? languages[0] : null);
              }
            }
          })
        );
      })
    );
  }

  private getInitLanguage(): Observable<LanguageInterface> {
    return new Observable(observer => {
      const lang = this.getFromStorage();
      if (lang) {
        observer.next(lang);
        observer.complete();
      } else {
        this.userService.getUser().subscribe(({ language }) => {
          observer.next(language);
          observer.complete();
        });
      }
      observer.next(null);
      observer.complete();
    });
  }

  getCurrentAsObservable() {
    return this.current$.asObservable();
  }

  getAll() {
    return this.values$.asObservable();
  }

  switch(lang: LanguageInterface, reload = false): Observable<any> {
    this.saveToStorage(lang);
    this.current$.next(lang);

    if (!reload) {
      // returns Observable which emits when translations file has been loaded
      return this.translateService.use(lang.short);
    }

    return EMPTY;
  }

  send(short: string) {
    const body = {
      language: short,
    };
    this.api.post('users/set-language', body).subscribe();
  }

  private getFromStorage(): LanguageInterface {
    return JSON.parse(localStorage.getItem(LanguageConstants.STORAGE_KEY));
  }

  private saveToStorage(value) {
    localStorage.setItem(LanguageConstants.STORAGE_KEY, JSON.stringify(value));
  }
}
