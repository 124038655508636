import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SaleMode} from './sale-mode.types';
import {Subscription} from 'rxjs';
import { ListModeSwitchService } from './list-mode-switch.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-list-mode-switch',
  templateUrl: './list-mode-switch.component.html',
  styleUrls: ['./list-mode-switch.component.scss']
})
export class ListModeSwitchComponent implements OnInit, OnDestroy {
  @Input() displayLabel = true;
  
  listModes = SaleMode;
  selectedMode: SaleMode;
  buttonId = 'list-mode-switch';
  private subscription: Subscription = new Subscription();

  constructor(private listModeSwitchService: ListModeSwitchService, private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode: SaleMode) => {
        this.selectedMode = saleMode;
      })
    );

    this.subscription.add(
      this.authService.authenticatedObservable().subscribe((authenticated: boolean) => {
        if (!authenticated) {
          ListModeSwitchService.clearLocalStorage();
        }
      })
    );
  }

  onChange(value) {
    this.selectedMode = +value;
    this.onModeChange();
  }

  onModeChange() {
    const mode = [SaleMode.SALE, SaleMode.ORDER].includes(this.selectedMode) ? this.selectedMode : SaleMode.ORDER;
    this.listModeSwitchService.setSaleMode(mode);
    ListModeSwitchService.updateLocalStorage(this.selectedMode);
  }

  ngOnDestroy() {
    this.listModeSwitchService.setSaleMode(SaleMode.ORDER);
    this.subscription.unsubscribe();
  }

  onButtonClick(event: MouseEvent) {
    if ((event.target as HTMLElement).id === this.buttonId) {
      this.onChange(this.selectedMode === SaleMode.ORDER ? SaleMode.SALE : SaleMode.ORDER);
    }
  }
}
