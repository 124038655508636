import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResizeNotifierService } from '../../../../resize-observer/resize-notifier.service';
import { ListModeSwitchService } from '../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { SaleMode } from '../../../../shared/components/list-mode-switch/sale-mode.types';
import { GeneralRoutePaths, InquiriesRoutePath } from '../../../enums/route-types.enum';
import { UserInterface } from '../../../models/user.model';
import { UserService } from '../../../services/user/user.service';
import { TreeService } from '../../tree.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagesModalComponent } from '../../../../messages-modal/messages-modal/messages-modal.component';
import { NotificationsModalComponent } from '../../../../notifications-modal/notifications-modal/notifications-modal.component';

enum ExpandableGroupsEnum {
  INQUIRIES,
  PROFILE,
}

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrl: './burger-menu.component.scss',
})
export class BurgerMenuComponent implements OnInit, OnDestroy {
  user: UserInterface;
  saleMode: SaleMode;
  expendableGroups = ExpandableGroupsEnum;
  expandedGroup: ExpandableGroupsEnum | null = null;
  inquiriesRoutePaths = InquiriesRoutePath;
  claimsRoutePath = `/${InquiriesRoutePath.ROOT}/${InquiriesRoutePath.CLAIMS}`;
  termsRoutePath = `/${GeneralRoutePaths.TERMS}`;
  private subscriptions = new Subscription();

  constructor(
    private userService: UserService,
    private listModeSwitchService: ListModeSwitchService,
    private router: Router,
    public resizeObserver: ResizeNotifierService,
    private treeService: TreeService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.userService.fromStorage().subscribe((user) => {
      this.user = user;
    });

    this.subscriptions.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode) => {
        this.saleMode = +saleMode;
      })
    );

    this.expandedGroup = this.isRouteActive(InquiriesRoutePath.ROOT) ? this.expendableGroups.INQUIRIES : this.expendableGroups.PROFILE;
  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, {
      paths: 'subset',
      queryParams: 'ignored',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  navigateTo(url: string): void {
    const alreadyActiveRoute = this.isRouteActive(url);

    if (alreadyActiveRoute) {
      return;
    }

    this.router.navigateByUrl(url);
  }

  logout(): void {
    // preserve picked language and drop everything else
    const userLanguage = sessionStorage.getItem('language');
    sessionStorage.clear();
    sessionStorage.setItem('language', userLanguage);

    this.router.navigate([`/${GeneralRoutePaths.LOGOUT}`]);
  }

  isCollapsed(group: ExpandableGroupsEnum): boolean {
    return group !== this.expandedGroup;
  }

  toggleCollapse(group: ExpandableGroupsEnum): void {
    if (group === this.expandedGroup) {
      this.expandedGroup = null;
      return;
    }

    this.expandedGroup = group;
  }

  onBackDropClick(): void {
    this.treeService.setBurgerMenuOpened(false);
  }

  onNotificationsModalOpen() {
    this.modalService.open(NotificationsModalComponent, {
      windowClass: 'messages-modal',
      scrollable: true,
    });
  }
  
  onMessagesModalOpen() {
    this.modalService.open(MessagesModalComponent, {
      windowClass: 'messages-modal',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
