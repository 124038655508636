import { PermissionInterface } from './permissions/decision-maker.service';
import { UserRole } from './core/enums/user-role.enum';
import { OrderRouteType, OrderRoutePath } from './core/enums/route-types.enum';
import { OrderInterface } from './core/models/order.model';
import { OrderState } from "./core/enums/order.state.enum";
import FeatureFlags from '../../featureFlags.json';

export const CAN_ADD_AX_CODE = 'CAN_ADD_AX_CODE';
export const CAN_EDIT_AX_CODE = 'CAN_EDIT_AX_CODE';
export const CAN_VIEW_AX_CODE = 'CAN_VIEW_AX_CODE';
export const CAN_DOWNLOAD_PROFORMA = 'CAN_DOWNLOAD_PROFORMA';
export const CAN_CHANGE_PRICELIST = 'CAN_CHANGE_PRICELIST';
export const CAN_CHANGE_LANGUAGE = 'CAN_CHANGE_LANGUAGE';
export const CAN_SEND_ORDER_TO_PM = 'CAN_SEND_ORDER_TO_PM';
export const CAN_SEND_ORDER_TO_AX = 'CAN_SEND_ORDER_TO_AX';
export const CAN_SWITCH_LIST_MODES = 'CAN_SWITCH_LIST_MODES';
export const CAN_CHANGE_SALE_COEFFICIENTS = 'CAN_CHANGE_SALE_COEFFICIENTS';
export const CAN_EDIT_OFFERS = 'CAN_EDIT_OFFERS';
export const CAN_SEE_ORDER_DISCOUNT_COLUMN = 'CAN_SEE_ORDER_DISCOUNT_COLUMN';
export const CAN_VIEW_SALE_MODE = 'CAN_VIEW_SALE_MODE';
export const CAN_VIEW_ORDER_MODE = 'CAN_VIEW_ORDER_MODE';
export const CAN_SEE_SALE_DISCOUNT_COLUMN = 'CAN_SEE_SALE_DISCOUNT_COLUMN';
export const CAN_MODIFY_DISCOUNTS_AND_CUSTOM_PRICES = 'CAN_MODIFY_DISCOUNTS_AND_CUSTOM_PRICES';
export const CAN_MODIFY_DISCOUNTS = 'CAN_MODIFY_DISCOUNTS';
export const CAN_ADD_OTHER_SUPPLIER_ITEM_TO_ORDER = 'CAN_ADD_OTHER_SUPPLIER_ITEM_TO_ORDER';
export const CAN_ADD_NON_STANDARD_ITEM_TO_ORDER = 'CAN_ADD_NON_STANDARD_ITEM_TO_ORDER';
export const CAN_ADD_NON_STANDARD_ITEM_TO_OFFER = 'CAN_ADD_NON_STANDARD_ITEM_TO_OFFER';
export const CAN_ADD_STANDARD_ITEM_TO_ORDER = 'CAN_ADD_STANDARD_ITEM_TO_ORDER';
export const CAN_ADD_STANDARD_ITEM_TO_OFFER = 'CAN_ADD_STANDARD_ITEM_TO_OFFER';
export const CAN_ADD_NON_STANDARD_ITEM = 'CAN_ADD_NON_STANDARD_ITEM';
export const CAN_ADD_PAGE_BREAK_TO_ORDER = 'CAN_ADD_PAGE_BREAK_TO_ORDER';
export const CAN_ADD_TEXT_TO_ORDER = 'CAN_ADD_TEXT_TO_ORDER';
export const CAN_ADD_CUSTOM_MADE = 'CAN_ADD_CUSTOM_MADE';
export const CAN_MODIFY_ORDER_ITEM_ROWS = 'CAN_MODIFY_ORDER_ITEM_ROWS';
export const CAN_COPY_ORDER_ITEM_ROWS = 'CAN_COPY_ORDER_ITEM_ROWS';
export const CAN_CUT_ORDER_ITEM_ROWS = 'CAN_CUT_ORDER_ITEM_ROWS';
export const CAN_PASTE_ORDER_ITEM_ROWS = 'CAN_PASTE_ORDER_ITEM_ROWS';
export const CAN_UNGROUP_ORDER_ITEM_ROWS = 'CAN_UNGROUP_ORDER_ITEM_ROWS';
export const CAN_GROUP_ORDER_ITEM_ROWS = 'CAN_GROUP_ORDER_ITEM_ROWS';
export const CAN_ADD_EXTRA_ITEM_TO_ORDER = 'CAN_ADD_EXTRA_ITEM_TO_ORDER';
export const CAN_EDIT_ORDER_ITEM_ROWS = 'CAN_EDIT_ORDER_ITEM_ROWS';
export const CAN_REMOVE_ORDER_ITEM_ROWS = 'CAN_REMOVE_ORDER_ITEM_ROWS';
export const CAN_ADD_ITEMS_TO_ORDER = 'CAN_ADD_ITEMS_TO_ORDER';
export const CAN_IMPORT_TO_ORDER = 'CAN_IMPORT_TO_ORDER';
export const CAN_IMPORT_TO_OFFER = 'CAN_IMPORT_TO_OFFER';
export const CAN_CREATE_ORDER_IN_ORDER_SELECT = 'CAN_CREATE_ORDER_IN_ORDER_SELECT';
export const CAN_MODIFY_ONLY_CUSTOM_DISCOUNT = 'CAN_MODIFY_ONLY_CUSTOM_DISCOUNT';
export const CAN_SEE_PRICE_WITH_VAT = 'CAN_SEE_PRICE_WITH_VAT';
export const CAN_CREATE_DOCUMENT = 'CAN_CREATE_DOCUMENT';
export const CAN_EDIT_SHIPPING_ADDRESSES = 'CAN_EDIT_SHIPPING_ADDRESSES';
export const CAN_EDIT_CUSTOMERS = 'CAN_EDIT_CUSTOMERS';
export const CAN_EXPORT_TO_PDF = 'CAN_EXPORT_TO_PDF';
export const CAN_CREATE_NEW_CLAIM = 'CAN_CREATE_NEW_CLAIM';
export const CAN_ADD_ITEM_BASED_ON_STANDARD_TO_PRICE_REQUEST = ' CAN_ADD_ITEM_BASED_ON_STANDARD_TO_PRICE_REQUEST';
export const CAN_EXPORT_TO_OBX = 'CAN_EXPORT_TO_OBX';

export const PermissionActions = {
  CAN_ADD_AX_CODE,
  CAN_EDIT_AX_CODE,
  CAN_VIEW_AX_CODE,
  CAN_DOWNLOAD_PROFORMA,
  CAN_CHANGE_LANGUAGE,
  CAN_CHANGE_PRICELIST,
  CAN_SEND_ORDER_TO_PM,
  CAN_SEND_ORDER_TO_AX,
  CAN_SWITCH_LIST_MODES,
  CAN_CHANGE_SALE_COEFFICIENTS,
  CAN_EDIT_OFFERS,
  CAN_SEE_ORDER_DISCOUNT_COLUMN,
  CAN_VIEW_SALE_MODE,
  CAN_VIEW_ORDER_MODE,
  CAN_SEE_SALE_DISCOUNT_COLUMN,
  CAN_MODIFY_DISCOUNTS_AND_CUSTOM_PRICES,
  CAN_MODIFY_DISCOUNTS,
  CAN_ADD_ITEMS_TO_ORDER,
  CAN_ADD_OTHER_SUPPLIER_ITEM_TO_ORDER,
  CAN_ADD_NON_STANDARD_ITEM_TO_ORDER,
  CAN_ADD_NON_STANDARD_ITEM_TO_OFFER,
  CAN_ADD_NON_STANDARD_ITEM,
  CAN_ADD_STANDARD_ITEM_TO_ORDER,
  CAN_ADD_STANDARD_ITEM_TO_OFFER,
  CAN_ADD_PAGE_BREAK_TO_ORDER,
  CAN_ADD_TEXT_TO_ORDER,
  CAN_ADD_CUSTOM_MADE,
  CAN_MODIFY_ORDER_ITEM_ROWS,
  CAN_COPY_ORDER_ITEM_ROWS,
  CAN_CUT_ORDER_ITEM_ROWS,
  CAN_PASTE_ORDER_ITEM_ROWS,
  CAN_UNGROUP_ORDER_ITEM_ROWS,
  CAN_GROUP_ORDER_ITEM_ROWS,
  CAN_ADD_EXTRA_ITEM_TO_ORDER,
  CAN_EDIT_ORDER_ITEM_ROWS,
  CAN_REMOVE_ORDER_ITEM_ROWS,
  CAN_IMPORT_TO_ORDER,
  CAN_IMPORT_TO_OFFER,
  CAN_CREATE_ORDER_IN_ORDER_SELECT,
  CAN_MODIFY_ONLY_CUSTOM_DISCOUNT,
  CAN_SEE_PRICE_WITH_VAT,
  CAN_CREATE_DOCUMENT,
  CAN_EDIT_SHIPPING_ADDRESSES,
  CAN_EDIT_CUSTOMERS,
  CAN_EXPORT_TO_PDF,
  CAN_CREATE_NEW_CLAIM,
  CAN_ADD_ITEM_BASED_ON_STANDARD_TO_PRICE_REQUEST,
  CAN_EXPORT_TO_OBX,
};

export const permissions: PermissionInterface[] = [
  {
    key: CAN_ADD_AX_CODE,
    value: [UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_EDIT_AX_CODE,
    value: [UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_CHANGE_PRICELIST,
    value: [UserRole.ROLE_PM, UserRole.ROLE_PM_RU, UserRole.ROLE_ADMIN] as string[],
  },
  {
    key: CAN_VIEW_AX_CODE,
    value: [UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_DOWNLOAD_PROFORMA,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_CHANGE_LANGUAGE,
    value: [UserRole.ROLE_PM_RU, UserRole.ROLE_PM, UserRole.ROLE_ADMIN] as string[],
  },
  {
    key: CAN_SEND_ORDER_TO_PM,
    value: [UserRole.ROLE_DEALER] as string[],
  },
  {
    key: CAN_SEND_ORDER_TO_AX,
    value: [UserRole.ROLE_PM, UserRole.ROLE_PM_RU, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_SWITCH_LIST_MODES,
    value: [UserRole.ROLE_DEALER] as string[],
  },
  {
    key: CAN_CHANGE_SALE_COEFFICIENTS,
    value: [UserRole.ROLE_ADMIN, UserRole.ROLE_DEALER] as string[],
  },
  {
    key: CAN_EDIT_OFFERS,
    value: [UserRole.ROLE_DEALER],
  },
  {
    key: CAN_SEE_ORDER_DISCOUNT_COLUMN,
    value: [UserRole.ROLE_PM, UserRole.ROLE_PM_RU, UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_SEE_SALE_DISCOUNT_COLUMN,
    value: [UserRole.ROLE_DEALER] as string[],
  },
  {
    key: CAN_VIEW_SALE_MODE,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_VIEW_ORDER_MODE,
    value: [UserRole.ROLE_ADMIN, UserRole.ROLE_DEALER, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_ADD_OTHER_SUPPLIER_ITEM_TO_ORDER,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_ADD_NON_STANDARD_ITEM_TO_ORDER,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_ADD_CUSTOM_MADE,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU, UserRole.ROLE_DEALER] as string[],
  },
  {
    key: CAN_ADD_NON_STANDARD_ITEM_TO_OFFER,
    value: [UserRole.ROLE_DEALER] as string[],
  },
  {
    key: CAN_ADD_STANDARD_ITEM_TO_ORDER,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM] as string[],
  },
  {
    key: CAN_EXPORT_TO_PDF,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM] as string[],
  },
  {
    key: CAN_ADD_STANDARD_ITEM_TO_OFFER,
    value: [UserRole.ROLE_DEALER] as string[],
  },
  {
    key: CAN_ADD_PAGE_BREAK_TO_ORDER,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_ADD_TEXT_TO_ORDER,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_MODIFY_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_COPY_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_CUT_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_PASTE_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_UNGROUP_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_GROUP_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_ADD_EXTRA_ITEM_TO_ORDER,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_EDIT_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_REMOVE_ORDER_ITEM_ROWS,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_ADD_ITEMS_TO_ORDER,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_IMPORT_TO_OFFER,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_IMPORT_TO_ORDER,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_MODIFY_DISCOUNTS_AND_CUSTOM_PRICES,
    value: [UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_MODIFY_DISCOUNTS,
    value: [UserRole.ROLE_PM, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM_RU] as string[],
  },
  {
    key: CAN_CREATE_ORDER_IN_ORDER_SELECT,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS] as string[],
  },
  {
    key: CAN_ADD_NON_STANDARD_ITEM,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_DEALER],
  },
  {
    key: CAN_SEE_PRICE_WITH_VAT,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_DEALER, UserRole.ROLE_ADMIN],
  },
  {
    key: CAN_CREATE_DOCUMENT,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_DEALER],
  },
  {
    key: CAN_EDIT_SHIPPING_ADDRESSES,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM, UserRole.ROLE_PM_RU, UserRole.ROLE_ADMIN],
  },
  {
    key: CAN_EDIT_CUSTOMERS,
    value: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_DEALER, UserRole.ROLE_ADMIN],
  },
  {
    key: CAN_CREATE_NEW_CLAIM,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM]
  },
  {
    key: CAN_ADD_ITEM_BASED_ON_STANDARD_TO_PRICE_REQUEST,
    value: FeatureFlags.oldNonStandardsRestrictionsEnabled ? [UserRole.ROLE_DEALER, UserRole.ROLE_PM] : []
  },
  {
    key: CAN_EXPORT_TO_OBX,
    value: [UserRole.ROLE_DEALER, UserRole.ROLE_PM, UserRole.ROLE_PM_NARBUTAS]
  },
];

const ACCESSIBLE_ORDER_STATES_BY_ORDER_TYPE_AND_ROLE = [
  // ROLE_DEALER
  {
    role: UserRole.ROLE_DEALER,
    type: OrderRouteType.OFFERS,
    states: [
      OrderState.DRAFT,
    ],
    path: OrderRoutePath.OFFERS
  },
  {
    role: UserRole.ROLE_DEALER,
    type: OrderRouteType.PENDING,
    states: [
      OrderState.WAITING,
      OrderState.IN_AX,
    ],
    path: OrderRoutePath.PENDING
  },
  {
    role: UserRole.ROLE_DEALER,
    type: OrderRouteType.ARCHIVE,
    states: [
      OrderState.AX_ARCHIVE_CONFIRMED,
      OrderState.AX_ARCHIVED,
    ],
    path: OrderRoutePath.ARCHIVE
  },
  {
    role: UserRole.ROLE_DEALER,
    type: OrderRouteType.CONFIRMED,
    states: [
      OrderState.AX_CONFIRMED,
    ],
    path: OrderRoutePath.CONFIRMED
  },
  {
    role: UserRole.ROLE_DEALER,
    type: OrderRouteType.LOADED,
    states: [
      OrderState.AX_LOADED,
    ],
    path: OrderRoutePath.LOADED
  },
  // ROLE_PM_NARBUTAS
  {
    role: UserRole.ROLE_PM_NARBUTAS,
    type: OrderRouteType.ORDERS,
    states: [
      OrderState.DRAFT,
      OrderState.WAITING,
    ],
    path: OrderRoutePath.ORDERS
  },
  {
    role: UserRole.ROLE_PM_NARBUTAS,
    type: OrderRouteType.CONFIRMED,
    states: [
      OrderState.IN_AX,
      OrderState.AX_CONFIRMED,
      OrderState.AX_LOADED,
      OrderState.AX_ARCHIVED,
      OrderState.AX_ARCHIVE_CONFIRMED,
    ],
    path: OrderRoutePath.CONFIRMED
  },
  // ROLE_PM
  {
    role: UserRole.ROLE_PM,
    type: OrderRouteType.WAITING,
    states: [
      OrderState.WAITING,
    ],
    path: OrderRoutePath.WAITING
  },
  {
    role: UserRole.ROLE_PM,
    type: OrderRouteType.PENDING,
    states: [
      OrderState.IN_AX,
    ],
    path: OrderRoutePath.PENDING
  },
  {
    role: UserRole.ROLE_PM,
    type: OrderRouteType.CONFIRMED,
    states: [
      OrderState.AX_CONFIRMED,
    ],
    path: OrderRoutePath.CONFIRMED
  },
  {
    role: UserRole.ROLE_PM,
    type: OrderRouteType.LOADED,
    states: [
      OrderState.AX_LOADED,
    ],
    path: OrderRoutePath.LOADED
  },
  {
    role: UserRole.ROLE_PM,
    type: OrderRouteType.ARCHIVE,
    states: [
      OrderState.AX_ARCHIVED,
      OrderState.AX_ARCHIVE_CONFIRMED,
    ],
    path: OrderRoutePath.ARCHIVE
  },
  // ROLE_PM_RU
  {
    role: UserRole.ROLE_PM_RU,
    type: OrderRouteType.WAITING,
    states: [
      OrderState.WAITING,
    ],
    path: OrderRoutePath.WAITING
  },
  {
    role: UserRole.ROLE_PM_RU,
    type: OrderRouteType.PENDING,
    states: [
      OrderState.IN_AX,
    ],
    path: OrderRoutePath.PENDING
  },
  {
    role: UserRole.ROLE_PM_RU,
    type: OrderRouteType.CONFIRMED,
    states: [
      OrderState.AX_CONFIRMED,
    ],
    path: OrderRoutePath.CONFIRMED
  },
  {
    role: UserRole.ROLE_PM_RU,
    type: OrderRouteType.LOADED,
    states: [
      OrderState.AX_LOADED,
    ],
    path: OrderRoutePath.LOADED
  },
  {
    role: UserRole.ROLE_PM_RU,
    type: OrderRouteType.ARCHIVE,
    states: [
      OrderState.AX_ARCHIVED,
      OrderState.AX_ARCHIVE_CONFIRMED,
    ],
    path: OrderRoutePath.ARCHIVE
  },
];

export function shouldAllowAccessOrderInRouteByRoleAndState(role: string, type: number, order: OrderInterface): boolean {
  const withSameTypeAndRole = ACCESSIBLE_ORDER_STATES_BY_ORDER_TYPE_AND_ROLE.find(item => item.type === type && role === item.role);

  if (!withSameTypeAndRole) {
    return false;
  }

  return withSameTypeAndRole.states.includes(order.state);
}

export function routeTypeByRoleAndOrderState(role: string, order: OrderInterface) {
  return ACCESSIBLE_ORDER_STATES_BY_ORDER_TYPE_AND_ROLE.find(item => item.states.includes(order.state) && role === item.role);
}
