import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';

import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './tree/navbar/navbar.component';
import { SharedModule } from '../shared/shared.module';
import { SubNavbarComponent } from './tree/sub-navbar/sub-navbar.component';
import { AuthGuard } from './guards/auth.guard';
import { UserGuard } from './guards/user.guard';
import { ConfiguratorService } from './services/configurator/configurator.service';
import { LoaderService } from '../ui-elements/loader/loader.service';
import { WindowRef } from './services/window-ref/window-ref.service';
import { UserService } from './services/user/user.service';
import { ModuleWithProviders } from '@angular/core';
import { CatalogueModule } from '../catalogue/catalogue.module';
import { ThirdNavbarComponent } from './tree/third-navbar/third-navbar.component';
import { DetailsComponent } from './tree/third-navbar/details/details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserModule } from '../user/user.module';
import { LogoutComponent } from './logout/logout.component';
import { AdminGuard } from './guards/admin.guard';
import { TranslateModule } from '@ngx-translate/core';
import { RegularUserGuard } from './guards/regular-user.guard';
import { NotAdminGuard } from './guards/not-admin.guard';
import { RegularUserGuardService } from './services/regular-user-guard/regular-user-guard.service';
import { ImpersonateService } from './impersonate/impersonate.service';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { UnimpersonateComponent } from './impersonate/unimpersonate/unimpersonate.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PriceListStoreHandlerService } from './services/price-list/price-list-store-handler.service';
import { LanguageStoreHandlerService } from './services/language/language-store-handler.service';
import { AddressService } from './services/address/address.service';
import { CountryService } from './services/country/country.service';
import { ClientService } from './services/client/client.service';
import { PermissionsModule } from '../permissions/permissions.module';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { PriceListCurrencyService } from './services/price-list-currency/price-list-currency.service';
import { NavbarTreeComponent } from './tree/navbar-tree/navbar-tree.component';
import { UncacheableResourceModule } from '../uncacheable-resource/uncacheable-resource.module';
import { CloseWithConfirmationGuard } from './guards/close-with-confirmation.guard.service';
import { SaleCoefficientFormatPipe } from '../shared/pipes/sale-coefficient-format/sale-coefficient-format.pipe';
import { AuthService } from '../auth/auth.service';
import { OrderItemsClipboardService } from '../orders/order-items-clipboard/order-items-clipboard.service';
import { LogoComponent } from './tree/logo/logo.component';
import { NavbarWrapperComponent } from './tree/navbar-wrapper/navbar-wrapper.component';
import { SimpleNavbarComponent } from './tree/simple-navbar/simple-navbar.component';
import { ResizeObserverModule } from '../resize-observer/resize-observer.module';
import { APP_DATE_LOCALE_ID, APP_DATE_LOCALE_ID_PROVIDER, LANGUAGE_INITIALIZER } from './locale/date-locale';
import { NgbDatepickerI18n, NgbDatepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap';
import { NavigationSearchInputComponent } from './tree/search/navigation-search-input.component';
import { RouterModule } from '@angular/router';
import { MobileNavbarComponent } from "./tree/mobile-navbar/mobile-navbar.component";
import { BurgerMenuComponent } from './tree/mobile-navbar/burger-menu/burger-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CatalogueModule,
    BrowserAnimationsModule,
    UserModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    PermissionsModule,
    LoaderModule,
    UncacheableResourceModule,
    ResizeObserverModule
  ],
  providers: [
    AuthService,
    CurrencyPipe,
    PriceListCurrencyService,
    AuthGuard,
    UserGuard,
    ConfiguratorService,
    LanguageStoreHandlerService,
    WindowRef,
    LoaderService,
    UserService,
    AdminGuard,
    RegularUserGuard,
    NotAdminGuard,
    RegularUserGuardService,
    ImpersonateService,
    PriceListStoreHandlerService,
    AddressService,
    CountryService,
    ClientService,
    TitleCasePipe,
    DecimalPipe,
    CloseWithConfirmationGuard,
    SaleCoefficientFormatPipe,
    OrderItemsClipboardService,
  ],
  declarations: [
    HomeComponent,
    NavbarComponent,
    SubNavbarComponent,
    ThirdNavbarComponent,
    DetailsComponent,
    NavigationSearchInputComponent,
    LogoutComponent,
    ImpersonateComponent,
    UnimpersonateComponent,
    NavbarTreeComponent,
    LogoComponent,
    NavbarWrapperComponent,
    SimpleNavbarComponent,
    MobileNavbarComponent,
    BurgerMenuComponent
  ],
  exports: [
    NavbarTreeComponent,
    NavbarComponent,
    LogoutComponent,
    ImpersonateComponent,
    UnimpersonateComponent,
    LogoComponent
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        AuthGuard,
        UserGuard,
        ConfiguratorService,
        LanguageStoreHandlerService,
        PriceListStoreHandlerService,
        WindowRef,
        LoaderService,
        UserService,
        AdminGuard,
        RegularUserGuard,
        NotAdminGuard,
        RegularUserGuardService,
        ImpersonateService,
        AddressService,
        CountryService,
        ClientService,
        OrderItemsClipboardService,
        LANGUAGE_INITIALIZER,
        APP_DATE_LOCALE_ID_PROVIDER,
        { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nDefault, deps: [APP_DATE_LOCALE_ID] }
      ],
    };
  }
}
