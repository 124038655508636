import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import localeLT from '@angular/common/locales/lt';
import localeRU from '@angular/common/locales/ru';
import localeFR from '@angular/common/locales/fr';
import localeDE from '@angular/common/locales/de';

registerLocaleData(localeLT, 'lt-LT');
registerLocaleData(localeRU, 'ru-RU');
registerLocaleData(localeFR, 'fr-FR');
registerLocaleData(localeDE, 'de-DE');

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './api.service';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { HeaderMessageModule } from './header-message/header-message.module';
import { UserService } from './core/services/user/user.service';
import { WITH_CREDENTIALS_INTERCEPTOR_PROVIDER } from './core/interceptors/with-credentials.interceptor';
import { WITH_XDEBUG_INTERCEPTOR_PROVIDER } from './core/interceptors/with-xdebug.interceptor';
import { ACCEPT_LANGUAGE_HEADER_INTERCEPTOR_PROVIDER } from './core/services/language/accept-language.interceptor';
import { PRICELIST_HEADER_INTERCEPTOR_PROVIDER } from './core/services/price-list/price-list.interceptor';
import { IMPERSONATED_USER_INTERCEPTOR } from './core/impersonate/impersonate.interceptor';
import { HeaderMessageService } from './header-message/header-message.service';
import { CONFIGURATOR_SESSION_INTERCEPTOR_PROVIDER } from './configurator-modal/session/session.interceptor';
import { SERVER_ERRORS_INTERCEPTOR } from './header-message/server-errors.interceptor';
import { SomethingWentWrongComponent } from './errors/something-went-wrong/something-went-wrong.component';
import { UnsupportedBrowserComponent } from './errors/unsupported-browser/unsupported-browser.component';
import { MessagesModalModule } from './messages-modal/messages-modal.module';
import { NotificationsModalModule } from './notifications-modal/notifications-modal.module';
import { PermissionsModule } from './permissions/permissions.module';
import { UserRolesArray } from './core/enums/user-role.enum';
import { permissions } from './permissions.config';
import { PermissionsUserService } from './permissions/user.model';
import { RoleRedirectorModule } from './role-redirector/role-redirector.module';
import { REDIRECTS_FACTORY_PROVIDER } from './role-redirector.config';
import { ROLE_REDIRECTOR_ROLES } from './role-redirector/injection.tokens';
import { TooltipModule } from './ui-elements/tooltip/tooltip.module';
import { VISIBILITY_BY_VARIABLES_PROPERTIES } from './visibility-by-variables/injection.tokens';
import { VisibilityByVariablesModule } from './visibility-by-variables/visibility-by-variables.module';
import { UNCACHEABLE_RESOURCE_QUERY_PARAM } from './uncacheable-resource/injection.tokens';
import { SystemVersionService } from './core/services/system-version/system-version.service';
import { UncacheableResourceModule } from './uncacheable-resource/uncacheable-resource.module';
import { RoleGuard } from './core/guards/role.guard';
import { DetectedAdblockerModalModule } from './ui-elements/detected-adblocker-modal/detected-adblocker-modal.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { ContentModule } from './content/content.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdateService } from './update.service';
import { ComponentInventoryModule } from './component-inventory/component-inventory.module';
import { ToastModule } from './ui-elements/toast/toast.module';
import { TermsModule } from './terms/terms.module';
import { GenericModalModule } from "./ui-elements/generic-modal/generic-modal.module";
import { ResizeNotifier, ResizeNotifierService } from './resize-observer/resize-notifier.service';

if (environment.production && environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    // To set your release version
    release: environment.release,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentryTracesSampleRate,
    beforeSend(event, hint) {
      const error = hint.originalException as Error;
      // Ignore HTTP errors
      if (error && error.name === 'HttpErrorResponse') {
        return;
      }
      return event;
    },
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    if (environment.production && environment.sentryDSN) {
      Sentry.captureException(error.originalError || error);
    }
    console.error(error);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY = () => {
  return {
    hidePrices: environment.hidePrices,
  };
};

export const VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY_PROVIDER = {
  provide: VISIBILITY_BY_VARIABLES_PROPERTIES,
  useFactory: VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY,
};

export const ROLES_FACTORY = () => {
  return UserRolesArray;
};

export const ROLES_FACTORY_PROVIDER = {
  provide: ROLE_REDIRECTOR_ROLES,
  useFactory: ROLES_FACTORY,
};

export const UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY = (systemVersionService: SystemVersionService) => {
  return systemVersionService.fetch();
};

export const UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY_PROVIDER = {
  provide: UNCACHEABLE_RESOURCE_QUERY_PARAM,
  useFactory: UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY,
  deps: [SystemVersionService],
};

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, SomethingWentWrongComponent, UnsupportedBrowserComponent],
  bootstrap: [AppComponent],
  imports: [
    /* Will go to Universal when @ng-boostrap carousel supports it */
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    FormsModule,
    SharedModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HeaderMessageModule,
    MessagesModalModule,
    NotificationsModalModule,
    PermissionsModule.forRoot({
      roles: UserRolesArray,
      permissions,
      userService: { provide: PermissionsUserService, useExisting: UserService },
    }),
    RoleRedirectorModule,
    TooltipModule,
    VisibilityByVariablesModule,
    UncacheableResourceModule.forRoot(),
    DetectedAdblockerModalModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    MaintenanceModule,
    InlineSVGModule.forRoot(),
    ContentModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    CommonModule,
    ComponentInventoryModule,
    ToastModule,
    CoreModule,
    TermsModule,
    GenericModalModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: 'googleTagManagerId',  useValue: environment.googleTagManagerId },
    { provide: 'googleTagManagerAuth',  useValue: environment.googleTagManagerAuth },
    { provide: 'googleTagManagerPreview',  useValue: environment.googleTagManagerPreview },
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    WITH_CREDENTIALS_INTERCEPTOR_PROVIDER,
    ...(environment.production ? [] : [WITH_XDEBUG_INTERCEPTOR_PROVIDER]),
    HeaderMessageService,
    ACCEPT_LANGUAGE_HEADER_INTERCEPTOR_PROVIDER,
    PRICELIST_HEADER_INTERCEPTOR_PROVIDER,
    CONFIGURATOR_SESSION_INTERCEPTOR_PROVIDER,
    SERVER_ERRORS_INTERCEPTOR,
    IMPERSONATED_USER_INTERCEPTOR,
    REDIRECTS_FACTORY_PROVIDER,
    ROLES_FACTORY_PROVIDER,
    VISIBILITY_BY_VARIABLES_PROPERTIES_FACTORY_PROVIDER,
    UNCACHEABLE_RESOURCE_QUERY_PARAM_FACTORY_PROVIDER,
    UpdateService,
    { provide: ResizeNotifierService, useValue: ResizeNotifier },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }
