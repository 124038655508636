import { Injectable } from '@angular/core';

import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, interval } from 'rxjs';
import { ModalComponent } from './shared/components/modal/modal.component';
@Injectable()
export class UpdateService {
  private modalOpened = false;

  constructor(private swUpdate: SwUpdate, private modalService: NgbModal) {
    if (!swUpdate.isEnabled) {
      window.console.info('SW updates disabled 🙁');

      return;
    }

    // make update check wright away on app load
    swUpdate.checkForUpdate();

    // Interval checks for update every 10 minutes
    interval(10 * 60 * 1000).subscribe(() => swUpdate.checkForUpdate());

    swUpdate.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_READY') {
        if (!this.modalOpened) {
          const modalRef = this.modalService.open(ModalComponent, {
            size: 'lg',
            keyboard: false,
            beforeDismiss: () => false,
          });
          modalRef.componentInstance.component = 'app-updated';
          this.modalOpened = true;
        }
      }
    });
  }
}
