import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { GalleryComponent } from './components/gallery/gallery.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MarkComponent } from './components/mark/mark.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ListModeSwitchComponent } from './components/list-mode-switch/list-mode-switch.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { HideOnSearchDirective } from './directives/hide-on-search/hide-on-search.directive';
import { HideOnSearchDataDirective } from './directives/hide-on-searc-data/hide-on-search-data.directive';
import { NoResultsFoundComponent } from './components/no-results-found/no-results-found.component';
import { GenericModalModule } from '../ui-elements/generic-modal/generic-modal.module';
import { VisibleForUserRoleDirective } from './directives/visible-for-user-role/visible-for-user-role.directive';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation/click-stop-propagation.directive';
import { PopoverPositionDirective } from './directives/popover-position/popover-position.directive';
import { ArrowDirective } from './directives/popover-position/arrow/arrow.directive';
import { ArrowBackgroundDirective } from './directives/popover-position/arrow/arrow-backgroud/arrow-background.directive';
import { ChangeWidthDirective } from './directives/change-width/change-width.directive';
import { PriceFormatPipe } from './pipes/price-format/price-format.pipe';
import { HideByFieldTypeDirective } from './directives/hide-by-field-type/hide-by-field-type.directive';
import { TimeAgoPipe } from './pipes/time-ago/time-ago.pipe';
import { ContainerDirective } from './directives/container/container.directive';
import { ActivatePricelistDirective } from './directives/activate-pricelist/activate-pricelist.directive';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { FileExtensionPipe } from './pipes/file-extension/file-extension.pipe';
import { FullScreenModeDirective } from './directives/full-screen-mode/full-screen-mode.directive';
import { AutoInputSizeDirective } from './directives/auto-input-size/auto-input-size.directive';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { PriceListCurrencyPipe } from './pipes/price-list-currency/price-list-currency.pipe';
import { UncacheableResourceModule } from '../uncacheable-resource/uncacheable-resource.module';
import { CommaPipe } from './pipes/comma/comma.pipe';
import { SaleCoefficientFormatPipe } from './pipes/sale-coefficient-format/sale-coefficient-format.pipe';
import { SaleCoefficientFormatDirective } from './directives/sale-coefficient-format/sale-coefficient-format.directive';
import { ParentBoundaryDirective } from './directives/parent-boundary/parent-boundary.directive';
import { SearchHighlightPipe } from './pipes/search-highlight/search-highlight.pipe';
import { DisplayedValueLimitPipe } from './pipes/displayed-value-limit/displayed-value-limit.pipe';
import { DisableByRoleDirective } from './directives/disable-by-role/disable-by-role.directive';
import { PricelistCurrencySymbolPipe } from './pipes/pricelist-currency-symbol/pricelist-currency-symbol.pipe';
import { FooterDatePickerComponent } from './components/footer-date-picker/footer-date-picker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from '../ui-elements/tooltip/tooltip.module';
import { LeaveModalComponent } from './components/leave-modal/leave-modal.component';
import { RequiredFieldsAsteriskPipe } from './pipes/required-fields-asterisk/required-fields-asterisk.pipe';
import { ShippingAddressPipe } from './pipes/shipping-address/shipping-address.pipe';
import { IsFormControlRequiredPipe } from './pipes/is-form-control-required/is-form-control-required.pipe';
import { I18nDatePipe } from './pipes/i18n-date/i18n-date.pipe';
import { AddressPipe } from './pipes/address/address.pipe';
import { ErrorService } from './services/error/error.service';
import { UploadDragDropDirective } from './directives/upload-drag-drop/upload-drag-drop.directive';
import { SecondsToMillisecondsPipe } from './pipes/seconds-to-miliseconds/seconds-to-miliseconds.pipe';
import { FormNativeElementDirective } from './directives/form-native-element/form-native-element.directive';
import { EmptyListComponent } from './empty-list/empty-list/empty-list.component';
import { AppReloadComponent } from './components/app-reload/app-reload.component';
import { AppUpdatedComponent } from './components/app-updated/app-updated.component';
import { PricelistDownloadComponent } from './components/pricelist-download/pricelist-download.component';
import { TranslateValidationErrorsPipe } from "./pipes/translate-validation-errors/translate-validation-errors.pipe";
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { EncodeUriPipe } from './pipes/encode-uri.pipe';
import { ConsentModalComponent } from './components/consent-modal/consent-modal.component';
import { CheckMarkModule } from '../ui-elements/check-mark/check-mark.module';
import { AlertComponent } from './components/alert/alert.component';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { ResizeImagePipe } from './pipes/resize-image/resize-image.pipe';
import { RowImageComponent } from './components/row-image/row-image.component';
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { TranslatedDatePipe } from './pipes/translated-date/translated-date.pipe';
import { FilesizeFormatPipe } from './pipes/filesize-format/filesize-format.pipe';
import { LinkifyPipe } from './pipes/linkify/linkify.pipe';
import { SearchResultsInfoComponent } from './components/search-results-info/search-results-info.component';
import { HighlightHtmlPipe } from './pipes/highlight-html/highlight-html.pipe';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        NgClickOutsideDirective,
        NgbModule,
        TranslateModule,
        FormsModule,
        GenericModalModule,
        UncacheableResourceModule,
        BsDatepickerModule,
        TooltipModule,
        CheckMarkModule
    ],
    exports: [
        GalleryComponent,
        AppReloadComponent,
        AppUpdatedComponent,
        ModalComponent,
        MarkComponent,
        NotificationComponent,
        NgClickOutsideDirective,
        NgbModule,
        ListModeSwitchComponent,
        BreadcrumbsComponent,
        HideOnSearchDirective,
        HideOnSearchDataDirective,
        NoResultsFoundComponent,
        VisibleForUserRoleDirective,
        DropDownComponent,
        PopoverPositionDirective,
        ArrowDirective,
        ArrowBackgroundDirective,
        ChangeWidthDirective,
        HideByFieldTypeDirective,
        PriceFormatPipe,
        ClickStopPropagationDirective,
        TimeAgoPipe,
        ContainerDirective,
        ActivatePricelistDirective,
        SafeHtmlPipe,
        FileExtensionPipe,
        FullScreenModeDirective,
        AutoInputSizeDirective,
        CapitalizePipe,
        PriceListCurrencyPipe,
        SaleCoefficientFormatDirective,
        CommaPipe,
        SaleCoefficientFormatPipe,
        ParentBoundaryDirective,
        SearchHighlightPipe,
        DisplayedValueLimitPipe,
        DisableByRoleDirective,
        PricelistCurrencySymbolPipe,
        FooterDatePickerComponent,
        LeaveModalComponent,
        RequiredFieldsAsteriskPipe,
        ShippingAddressPipe,
        IsFormControlRequiredPipe,
        I18nDatePipe,
        AddressPipe,
        UploadDragDropDirective,
        SecondsToMillisecondsPipe,
        FormNativeElementDirective,
        PricelistDownloadComponent,
        TranslateValidationErrorsPipe,
        LanguageSwitchComponent,
        EncodeUriPipe,
        ConsentModalComponent,
        AlertComponent,
        SearchableSelectComponent,
        ResizeImagePipe,
        RowImageComponent,
        MaintenanceBannerComponent,
        TranslatedDatePipe,
        FilesizeFormatPipe,
        LinkifyPipe,
        SearchResultsInfoComponent,
        HighlightHtmlPipe,
    ],
    declarations: [
        ContainerDirective,
        ActivatePricelistDirective,
        GalleryComponent,
        AppReloadComponent,
        AppUpdatedComponent,
        ModalComponent,
        MarkComponent,
        NotificationComponent,
        ListModeSwitchComponent,
        BreadcrumbsComponent,
        HideOnSearchDirective,
        HideOnSearchDataDirective,
        NoResultsFoundComponent,
        VisibleForUserRoleDirective,
        DropDownComponent,
        ClickStopPropagationDirective,
        PopoverPositionDirective,
        ArrowDirective,
        ArrowBackgroundDirective,
        ChangeWidthDirective,
        HideByFieldTypeDirective,
        PriceFormatPipe,
        TimeAgoPipe,
        SafeHtmlPipe,
        FileExtensionPipe,
        FullScreenModeDirective,
        AutoInputSizeDirective,
        CapitalizePipe,
        PriceListCurrencyPipe,
        SaleCoefficientFormatDirective,
        CommaPipe,
        SaleCoefficientFormatPipe,
        ParentBoundaryDirective,
        SearchHighlightPipe,
        DisplayedValueLimitPipe,
        DisableByRoleDirective,
        PricelistCurrencySymbolPipe,
        FooterDatePickerComponent,
        LeaveModalComponent,
        RequiredFieldsAsteriskPipe,
        ShippingAddressPipe,
        IsFormControlRequiredPipe,
        I18nDatePipe,
        AddressPipe,
        UploadDragDropDirective,
        SecondsToMillisecondsPipe,
        FormNativeElementDirective,
        EmptyListComponent,
        PricelistDownloadComponent,
        TranslateValidationErrorsPipe,
        LanguageSwitchComponent,
        EncodeUriPipe,
        ConsentModalComponent,
        AlertComponent,
        SearchableSelectComponent,
        ResizeImagePipe,
        RowImageComponent,
        MaintenanceBannerComponent,
        TranslatedDatePipe,
        FilesizeFormatPipe,
        LinkifyPipe,
        SearchResultsInfoComponent,
        HighlightHtmlPipe,
    ],
    providers: [ErrorService, I18nDatePipe, ShippingAddressPipe, PriceFormatPipe, CommaPipe, ResizeImagePipe, EncodeUriPipe, provideHttpClient(withInterceptorsFromDi())]
})
export class SharedModule { }
