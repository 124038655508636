import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CatalogueService} from '../../../catalogue.service';
import {TranslateService} from '@ngx-translate/core';
import {ArticleComponentInterface, ColumnsInterface} from '../../../../core/models/catalogue-article.model';
import {LoaderService} from '../../../../ui-elements/loader/loader.service';
import {LoaderComponent} from '../../../../ui-elements/loader/loader.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-article-components',
  templateUrl: './article-components.component.html',
  styleUrls: ['./article-components.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleComponentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() code: string;
  @Input() weight: number | null;
  @Input() volume: number | null;

  private subscriptions: Subscription = new Subscription();
  columns: ColumnsInterface;
  components: ArticleComponentInterface[] = [];
  totalQnt: number;
  @ViewChild('loader', { static: true }) loader: LoaderComponent;

  constructor(
    private catalogueService: CatalogueService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.translate.get('CATALOGUE.ARTICLE_LIST.COMPONENTS_TABLE_COLUMNS')
      .subscribe((translation: string) => {
        this.columns = {
          components: translation['COMPONENTS'],
          code: translation['CODE'],
          weight: translation['WEIGHT'],
          volume: translation['VOLUME'] + ' (m3)',
          qnt: translation['QNT']
        };
      }));
  }

  ngOnChanges() {
    this.subscriptions.add(this.loaderService.load(this.catalogueService.getArticleComponentsByCode(this.code), this.loader)
      .subscribe((data: Array<any>) => {
          this.components = data;
          this.totalQnt = this.components.reduce((acc, component) => acc + component.qnt, 0);

          this.changeDetector.markForCheck();
        }
      ));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
