import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import * as moment from 'moment';
import { DateHelper } from '../core/util/date.helper';
import { LocalStorageKeys } from '../core/constants/local-storage.constants';
import { DOCUMENT } from '@angular/common';

export interface MaintenancePlanInterface {
  dateFrom?: string;
  dateTo?: string;
  bannerActiveFrom?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  private renderer: Renderer2;
  
  public maintenancePlan$ = new BehaviorSubject<MaintenancePlanInterface>(null);
  public maintenanceBannerDismissed$ = new BehaviorSubject<boolean>(false);

  constructor(
    private api: ApiService,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  dismissMaintenanceBanner(): void {
    const plan = this.maintenancePlan$.getValue();
    
    let dismissedUntil = moment(plan?.dateFrom).subtract(2, 'hours')

    if (moment(plan?.dateFrom).diff(moment(), 'hours') < 2) {
      dismissedUntil = moment(plan?.dateFrom);
    }

    localStorage.setItem(LocalStorageKeys.MAINTENANCE_DISMISSED_UNTIL_KEY, dismissedUntil.format());
    this.maintenanceBannerDismissed$.next(true);

    this.renderer.removeClass(this.document.getElementById('app'), 'has-maintenance-banner');
  }

  fetchPlan(): void {
    this.getPlan().subscribe(plan => {
      this.maintenancePlan$.next(plan);

      const dismissedUntil = localStorage.getItem(LocalStorageKeys.MAINTENANCE_DISMISSED_UNTIL_KEY);
      const isDismissed = !!dismissedUntil && moment().isBefore(moment(dismissedUntil));
      
      const now = moment();
      const activeFrom = moment(plan?.bannerActiveFrom);
      const activeTo = moment(plan?.dateTo);
      
      const isWithinActivePeriod = now.isBetween(activeFrom, activeTo);
  
      this.maintenanceBannerDismissed$.next(!isWithinActivePeriod || isDismissed);

      if (isWithinActivePeriod && !isDismissed) {
        this.renderer.addClass(this.document.getElementById('app'), 'has-maintenance-banner');
      }
    });
  }

  getPlan(): Observable<MaintenancePlanInterface> {
    return this.api.get('maintenance-mode/plan').pipe(
       map(({ data }) => {
        if (data?.dateFrom) {
          data.dateFrom = DateHelper.convertUtcToIso(data.dateFrom);
        }

        if (data?.dateTo) {
          data.dateTo = DateHelper.convertUtcToIso(data.dateTo);
        }

        if (data?.bannerActiveFrom) {
          data.bannerActiveFrom = DateHelper.convertUtcToIso(data.bannerActiveFrom);
        }

        return data;
      }),
    ) as Observable<MaintenancePlanInterface>;
  }
}
