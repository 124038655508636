<app-loader #loader [cssClasses]="'loader--white'"></app-loader>

<!-- Header -->
<ng-container *ngIf="{
  isLoading: isLoading$ | async,
  articlesLength: articlesLength$ | async,
  breadcrumbs: breadcrumbs$ | async,
  isSearch: isSearch$ | async
} as data">
  <div class="d-flex align-items-end justify-content-between w-100 my-3 px-md-3">
    <div class="me-3">
      <app-breadcrumbs [breadcrumbs]="data.breadcrumbs" [withLeadingChevron]="true" *ngIf="data.breadcrumbs?.length"></app-breadcrumbs>

      <h1 class="mx-0 mb-0"
          [class.mt-0]="data.breadcrumbs?.length"
          [class.mt-3]="!data.breadcrumbs?.length"
      >
        <app-search-results-info 
          *ngIf="!data.isLoading && searchQuery" [searchTerm]="searchQuery" [resultsFound]="!!(data.isSearch && data.articlesLength)"
        ></app-search-results-info>
        <ng-container *ngIf="pageTitle">{{ isNameModified ? pageTitle : (pageTitle | titlecase) }}</ng-container>
      </h1>
    </div>

    <div class="d-flex" [appHideOnSearchData]="data.articlesLength">
      <div ngbDropdown placement="bottom-end" *ngIf="!data.isLoading && data.articlesLength">
        <button
          class="btn btn-light small d-flex align-items-center fw-bold no-after"
          ngbDropdownToggle
        >
          {{ 'CATALOGUE.EXPORT.TITLE' | translate }}
          <i class="icon ni-file-down ms-2"></i>
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu--grouped shadow">
          <button
            ngbDropdownItem
            class="hover-bg-gray-200 color-gray-600 hover-color-black py-2 ps-3"
            appAutoDownload
            [exportCallback]="onExportCallback(catalogueExportFormat.PDF)"
            [saveData]="getAutoDownloadObject(catalogueExportFormat.PDF)"
          >
              <span class="d-flex align-items-center py-1">
                <i class="icon ni-download me-3"></i>
                {{ 'CATALOGUE.EXPORT.PDF' | translate }}
              </span>
          </button>
          <button
            ngbDropdownItem
            class="hover-bg-gray-200 color-gray-600 hover-color-black py-2 ps-3"
            appAutoDownload
            [exportCallback]="onExportCallback(catalogueExportFormat.XLSX)"
            [saveData]="getAutoDownloadObject(catalogueExportFormat.XLSX)"
          >
              <span class="d-flex align-items-center py-1">
                <i class="icon ni-download me-3"></i>
                {{ 'CATALOGUE.EXPORT.XLSX' | translate }}
              </span>
          </button>
        </div>
      </div>
      <div class="btn-group d-flex align-items-center ms-3" *appHideOnSearch>
        <a [routerLink]="[]"
          [queryParams]="viewModeQueryParams(viewMode.LIST)"
          queryParamsHandling="merge"
          class="btn p-2 d-flex"
          [ngClass]="{
              'btn-light': viewValue !== viewMode.LIST,
              'btn-dark': viewValue === viewMode.LIST
            }"
        ><i class="icon ni-view-list"></i></a>
        <a [routerLink]="[]"
          [queryParams]="viewModeQueryParams(viewMode.THUMBNAIL)"
          queryParamsHandling="merge"
          class="btn p-2 d-flex"
          [ngClass]="{
              'btn-light': viewValue !== viewMode.THUMBNAIL,
              'btn-dark': viewValue === viewMode.THUMBNAIL
            }"
        ><i class="icon ni-apps"></i></a>
      </div>
    </div>
  </div>

  <!-- Products -->
  <div class="container">
    <div class="row" *ngIf="{
      filters: filters$ | async,
      loading: filtersLoading$ | async
    } as filterData">
      <div
        *ngIf="!data.isSearch && (filterData.loading || filterData.filters?.length)"
        class="col ps-0 ps-md-3 filter-sidebar-col"
      >
        <div *ngIf="!filterData.loading && filterData.filters?.length"
          class="sticky mt-n3 pt-3"
          #sidebarFilters
          [style.top.px]="topSidebarPosition"
        >
          <app-filter-sidebar class="filter-sidebar"
            [filters]="filterData.filters"
            (toggleFilter)="onFilterToggle()"
          ></app-filter-sidebar>
        </div>
        <div *ngIf="filterData.loading" class="position-relative py-3">
          <app-loader [loading]="true"></app-loader>
        </div>
      </div>
      <div class="col px-0 px-md-3 position-relative">
        <ng-container *appHideOnSearch>
          <app-no-results-found *ngIf="!filterData.loading && !data.isLoading && !data.articlesLength" [cssClasses]="'no-data--top'"></app-no-results-found>
        </ng-container>
        <app-products-list-item-skeleton *ngIf="data.isLoading"></app-products-list-item-skeleton>
        <ng-container [ngSwitch]="viewValue">
          <ng-container *ngSwitchCase="viewMode.LIST">
            <ng-container *ngIf="systems$ | async as systems">
              <app-products-list-view *ngIf="!data.isLoading"
                [systems]="systems"
                [searchQuery]="searchQuery"
                (showMore)="showMore()">
              </app-products-list-view>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="viewMode.THUMBNAIL">
            <ng-container *ngIf="groupedSystems$ | async as groupedSystems">
              <app-products-thumbail-view *ngIf="!data.isLoading"
                [groupedSystems]="groupedSystems"
                [hasFilters]="!!filterData.filters?.length"
              ></app-products-thumbail-view>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
