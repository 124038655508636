import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-results-info',
  templateUrl: './search-results-info.component.html',
})
export class SearchResultsInfoComponent {
  @Input() searchTerm: string;
  @Input() resultsFound: boolean;

  get wrappedSearchQuery(): string {
    return `<span class="color-black">"${this.searchTerm}"</span>`;
  }
}
